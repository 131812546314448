<template>
  <div>
    <S2SForm :title="`Edit ${selectedProduct.name}`">
      <v-btn
        slot="toolbar-content"
        color="accent"
        @click="dialogProduct = true"
      >
        Preview Product
      </v-btn>

      <S2SCard
        title="Product"
        toolbar-class="elevation-1"
        color="white"
        toolbarColor="white"
        :containerProps="{ 'elevation-1': true }"
      >
        <v-form ref="productForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="productModel.name"
                label="Product Name"
                name="name"
                :rules="[(v) => !!v || 'Name field is required!']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="productModel.shortDescription"
                label="Short Description"
                name="shortDescription"
                :rules="[(v) => !!v || 'Short Description field is required!']"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-textarea
                v-model="productModel.description"
                label="Long Description"
                name="description"
                auto-grow
                rows="2"
                :rules="[(v) => !!v || 'Long Description field is required!']"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="productModel.price"
                label="Price"
                name="price"
                :rules="[
                  (v) => !!v || 'Price field is required!',
                  (v) => /^\d+(\.\d+)?$/.test(v) || 'Price format incorrect.',
                ]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="productModel.sku"
                label="SKU"
                name="sku"
                :rules="[(v) => !!v || 'SKU field is required!']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="productModel.brandName"
                label="Brand Name"
                name="brandName"
                :rules="[(v) => !!v || 'Brand Name field is required!']"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                label="Categories"
                :items="categories"
                item-text="name"
                v-model="productModel.categories"
                name="categories"
                multiple
                return-object
                chips
                small-chips
                deletable-chips
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                label="Labels"
                :items="labels"
                item-text="name"
                v-model="productModel.labels"
                name="labels"
                multiple
                return-object
                chips
                small-chips
                deletable-chips
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="productModel.position"
                label="Position"
                name="position"
                :rules="[(v) => !!v || 'Position field is required!']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                label="Options"
                :items="options"
                item-text="name"
                v-model="productModel.optionSets"
                name="optionSets"
                multiple
                return-object
                chips
                small-chips
                deletable-chips
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-checkbox
                v-model="productModel.available"
                label="In Stock"
                name="available"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="productModel.availableOnline"
                label="Available on App"
                name="availableOnline"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" md="6">
              <v-checkbox
                v-model="productModel.enabled"
                label="Active"
                name="enabled"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>

        <v-divider class="ma-5"></v-divider>
        <v-flex class="text-right pb-2">
          <v-btn text @click="cancelEdit">Cancel</v-btn>
          <v-btn color="accent" @click="updateContinue">Update Product</v-btn>
        </v-flex>
      </S2SCard>

      <v-divider class="ma-5"></v-divider>

      <S2SCard :title="`${selectedProduct.name} options`">
        <div
          v-if="
            selectedProduct.optionSets && !selectedProduct.optionSets.length
          "
        >
          <p>No option set selected.</p>
          <v-btn color="primary" @click="onOptionSetDialog"
            >Create new option set</v-btn
          >
        </div>
        <div v-else>
          <v-data-table
            :headers="optionSetHeaders"
            :items="selectedProduct.optionSets"
            single-expand
            show-expand
            :expanded="selectedProduct.optionSets"
            hide-default-footer
            :loading="loading"
          >
            <template v-slot:item.displayName="props">
              <v-edit-dialog
                :return-value.sync="props.item.displayName"
                @save="save(props.item, 'optionSet')"
                @cancel="cancel"
                @close="close"
              >
                {{ props.item.displayName }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.displayName"
                    :rules="[
                      (v) => v.length <= 25 || 'Input too long!',
                      (v) => !!v || 'Name field is required!',
                    ]"
                    label="Name"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <!-- expand column -->
            <template
              v-slot:item.data-table-expand="{ item, isExpanded, expand }"
            >
              <v-btn @click="expand(true)" v-show="!isExpanded" icon>
                <v-icon>mdi-eye</v-icon>
                <v-badge
                  overlap
                  :content="
                    item.options && item.options.length
                      ? item.options.length
                      : '0'
                  "
                  color="white"
                ></v-badge>
              </v-btn>
              <v-btn @click="expand(false)" v-show="isExpanded" icon dark>
                <v-icon>mdi-eye-off</v-icon>
                <v-badge
                  overlap
                  :content="
                    item.options && item.options.length
                      ? item.options.length
                      : '0'
                  "
                  color="white"
                ></v-badge>
              </v-btn>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    color="accent"
                    @click="
                      dialog = true;
                      optionId = item.id;
                    "
                    v-on="on"
                  >
                    <v-icon>mdi-plus-box</v-icon>
                  </v-btn>
                </template>
                <span>Add option</span>
              </v-tooltip>
            </template>

            <!-- expand item/row -->
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-2">
                <v-data-table
                  :headers="[
                    { text: 'Pack Size', value: 'name' },
                    { text: 'Price', value: 'price' },
                    { text: 'SKU', value: 'sku' },
                    {
                      text: 'Active',
                      value: 'active',
                      sortable: false,
                      width: '80px',
                    },
                  ]"
                  :items="item.options"
                  hide-default-footer
                  :mobile-breakpoint="0"
                >
                  <template v-slot:item.name="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.name"
                      @save="save(props.item, 'optionSetItem')"
                      @cancel="cancel"
                      @close="close"
                    >
                      {{ props.item.name }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.name"
                          :rules="[
                            (v) => !!v || 'Pack Size field is required!',
                          ]"
                          label="Pack Size"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:item.price="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.price"
                      @save="save(props.item, 'optionSetItem')"
                      @cancel="cancel"
                      @close="close"
                    >
                      {{ formatCurrency(props.item.price) }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.price"
                          :rules="[
                            (v) => !!v || 'Price field is required!',
                            (v) =>
                              /^\d+(\.)?\d+$/.test(v) ||
                              'Price format incorrect.',
                          ]"
                          label="Price"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:item.sku="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.sku"
                      @save="save(props.item, 'optionSetItem')"
                      @cancel="cancel"
                      @close="close"
                    >
                      {{ props.item.sku }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.sku"
                          :rules="[(v) => !!v || 'SKU field is required!']"
                          label="SKU"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:item.active="{ item }">
                    <v-btn icon @click="disableOption(item)">
                      <v-icon color="accent" v-if="item.enabled" medium
                        >mdi-checkbox-marked</v-icon
                      >
                      <v-icon v-else-if="!item.enabled" medium
                        >mdi-close-box</v-icon
                      >
                    </v-btn>
                  </template>
                </v-data-table>
              </td>
            </template>
            <template v-slot:item.active="{ item }">
              <v-icon color="accent" v-if="item.enabled" medium
                >mdi-checkbox-marked</v-icon
              >
              <v-icon v-else-if="!item.enabled" medium>mdi-close-box</v-icon>
            </template>
          </v-data-table>
        </div>
      </S2SCard>

      <v-divider class="ma-5"></v-divider>

      <!-- Dialogs -->
      <v-dialog v-model="dialogSet" width="500" persistent>
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Create New Option Set
          </v-card-title>

          <v-form ref="optionSetForm" lazy-validation class="pa-5">
            <v-layout wrap>
              <v-flex xs12 sm6 px-2>
                <v-text-field
                  :rules="[(v) => !!v || 'Name is required']"
                  v-model="optionSet['name']"
                  label="Option Set Name"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 px-2>
                <!-- <v-text-field :rules="[v => !!v || 'Description is required']" v-model="optionSet['description']" label="Option Set Description"></v-text-field> -->
              </v-flex>
            </v-layout>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="onOptionSetCancel">Cancel</v-btn>
              <v-btn color="accent" @click="onOptionSetAdd()">Create</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Create new option
          </v-card-title>

          <v-form ref="optionForm" class="pa-5">
            <v-layout row wrap>
              <v-flex xs12 sm6 px-2>
                <v-text-field
                  label="Pack Size"
                  :name="`optionItemName-${optionId}`"
                  :rules="[(v) => !!v || 'Pack Size is required']"
                  v-model="optionSetItemModel[`optionItemName-${optionId}`]"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 px-2>
                <v-text-field
                  label="Option Price"
                  v-money="currencyOptions"
                  v-model="optionSetItemModel[`optionItemPrice-${optionId}`]"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 px-2>
                <v-text-field
                  label="Option SKU"
                  :name="`optionItemSKU-${optionId}`"
                  v-model="optionSetItemModel[`optionItemSKU-${optionId}`]"
                  messages="optional"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="onCancel(optionId)">Cancel</v-btn>
              <v-btn color="accent" @click="onOptionItemAdd(optionId)"
                >Create</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <!-- product dialog -->
      <v-dialog v-model="dialogProduct" width="500">
        <v-card color="#1a2e46" class="text-center pb-1">
          <v-card-title class="headline grey lighten-2 text-center">
            Product Preview
          </v-card-title>

          <div class="product-preview">
            <div class="header">
              <div class="product-title">{{ selectedProduct.name }}</div>
              <div class="sub-title">
                {{ selectedProduct.shortDescription }}
              </div>
            </div>
            <div class="image">
              <div class="tags" v-if="hasLabel">
                <span>{{ hasLabel.name }}</span>
              </div>
              <img
                v-if="selectedProduct.images && selectedProduct.images.length"
                :src="selectedProduct.images[0].name"
              />
              <div class="discount" v-if="selectedProduct.discount">
                {{ selectedProduct.discount }}%
              </div>
            </div>
            <div class="footer pa-1">
              {{ hasOptions }} {{ getCheapestOption() }}
            </div>
          </div>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snack" :timeout="5000" :color="snackColor">
        {{ snackText }}
        <v-btn text @click="snack = false">Close</v-btn>
      </v-snackbar>
    </S2SForm>
  </div>
</template>

<script>
  import Vue from "vue";
  import productEditSchema from "@/static/config/forms/product-edit.json";
  import { ProductCriteria } from "../../api/products";
  import { Lookups } from "@/api/lookups";
  import { Utils } from "@/utils/";

  const VMoney = require("v-money").VMoney;

  export default Vue.extend({
    name: "ProductEdit",

    directives: { money: VMoney },

    props: {
      productId: {
        type: String,
        default: "",
      },
    },

    data: function () {
      let currencyOptions = {
        decimal: ".",
        thousands: " ",
        prefix: "R ",
        precision: 2,
        masked: false,
      };
      let optionSet = {
        name: "",
        displayName: "",
        description: "",
      };
      let optionSetItem = {
        name: "",
        displayName: "",
        description: "",
      };

      let productEditSchemaData = productEditSchema;
      let fetchLookups = Lookups.fetchLookups;

      return {
        productModel: {
          name: "",
          shortDescription: "",
          description: "",
          price: "",
          sku: "",
          brandName: "",
          categories: [],
          labels: [],
          optionSets: [],
          position: 1,
          available: false,
          availableOnline: false,
          enabled: false,
        },
        categories: [],
        labels: [],

        currencyOptions,
        optionSetId: 0,
        optionSet,
        optionSetItem,
        options: [],
        optionsValid: false,
        optionSetItemModel: {},
        showDisabledOptions: false,

        optionSetHeaders: [
          { text: "Name", value: "displayName" },
          {
            text: "Options",
            value: "data-table-expand",
            align: "center",
            sortable: false,
            width: "110px",
          },
          {
            text: "Active",
            value: "active",
            align: "center",
            sortable: false,
            width: "80px",
          },
        ],

        dialogSet: false,
        dialog: false,
        optionId: 0,
        updateSuccess: true,

        dialogProduct: false,

        hasLabel: null,
        hasOptions: "",

        snack: false,
        snackColor: "",
        snackText: "",

        productEditSchemaData,
        fetchLookups,
      };
    },

    computed: {
      selectedProduct: function () {
        return this.$store.state.products.selectedProduct;
      },
      loading: function () {
        return this.$store.state.products.loading;
      },
    },

    watch: {
      selectedProduct: function () {
        this.productModel = this.$store.state.products.selectedProduct;

        // seperate categories & labels
        this.productModel.categories = this.productModel.tags.filter((val) => {
          return val.tagType.name == "menu";
        });
        this.productModel.labels = this.productModel.tags.filter((val) => {
          return val.tagType.name == "label";
        });

        if (
          this.selectedProduct.optionSets &&
          this.selectedProduct.optionSets.length
        ) {
          this.optionSetId = this.selectedProduct.optionSets[0].id;
        }
        this.checkTags(this.selectedProduct.tags);
      },
    },

    methods: {
      getCategories: async function () {
        const response = await Lookups.fetchLookups("categories");
        this.categories = response.data.results.filter((val) => {
          return val.tagType.name == "menu";
        });
        this.labels = response.data.results.filter((val) => {
          return val.tagType.name == "label";
        });
      },
      getOptions: async function () {
        const response = await Lookups.fetchLookups("options");
        this.options = response.data.results;
      },
      async onOptionSetAdd() {
        if (this.$refs.optionSetForm.validate()) {
          this.optionSet.displayName = this.optionSet.name;
          const success = await this.$store.dispatch(
            "options/createOptionSetAndLink",
            { optionData: this.optionSet, product: this.selectedProduct }
          );

          if (success) {
            // clear form
            this.$refs.optionSetForm.reset();
            this.dialogSet = false;
          }
        }
      },
      onOptionItemAdd(itemId) {
        if (this.$refs.optionForm.validate()) {
          const optionItemObj = {
            name: this.optionSetItemModel[`optionItemName-${itemId}`],
            price: +this.optionSetItemModel[
              `optionItemPrice-${itemId}`
            ].replace(/[a-z]/gi, ""),
            sku: this.optionSetItemModel[`optionItemSKU-${itemId}`],
            setId: itemId,
            productId: this.selectedProduct.id,
          };

          this.$store.dispatch("options/createOptionItem", optionItemObj);

          this.$refs.optionForm.reset();
          // v-money clear workaround
          this.$refs.optionForm.$el.getElementsByTagName("input")[1].value = 0;

          this.dialog = false;
        }
      },
      save(option) {
        if (option.name == "" || option.description == "") {
          this.updateSuccess = false;
          return;
        }

        this.$store.dispatch("options/updateOptionSetItem", {
          optionItem: {
            name: option.name,
            price: option.price,
            sku: option.sku,
            id: option.id,
            productId: this.selectedProduct.id,
          },
          optionSetId: this.optionSetId,
        });
      },
      cancel(option) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Canceled";
      },
      close() {
        if (!this.updateSuccess) {
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Data could not be saved";
          this.updateSuccess = !this.updateSuccess;
        }
      },
      disableOption(item) {
        const itemObj = {
          item: {
            id: item.id,
            name: item.name,
            enabled: item.enabled,
            productId: this.selectedProduct.id,
          },
        };

        this.$store.dispatch("options/disableOptionSetItem", {
          optionItem: itemObj,
          optionSetId: this.optionSetId,
          showDisabled: this.showDisabledOptions,
        });
      },
      async updateContinue() {
        const productForm = this.$refs.productForm;
        let allOK = await productForm.validate();
        if (allOK) {
          try {
            if (this.productModel.categories && this.productModel.labels) {
              this.productModel.tags = this.productModel.categories.concat(
                this.productModel.labels
              );

              // delete this field as api does not support it
              delete this.productModel.categories;
              delete this.productModel.labels;
            }

            const success = await this.$store.dispatch(
              "products/productUpdate",
              { body: this.productModel, inline: false }
            );

            // **NB get product here
            this.$store.dispatch(
              "products/getProductById",
              this.selectedProduct.id
            );
          } catch (error) {
            // Placeholder, we dont need anything here
          }
        }
      },
      cancelEdit() {
        this.$router.push({ name: "products" });
      },
      formatCurrency(text) {
        return Utils.formatText(text, Utils.TextType.CURRENCY);
      },
      getCheapestOption() {
        let cheapestOption = 0;
        if (this.selectedProduct) {
          if (
            this.selectedProduct.optionSets &&
            this.selectedProduct.optionSets.length
          ) {
            this.selectedProduct.optionSets.forEach((optionSet) => {
              if (optionSet.options && optionSet.options.length) {
                optionSet.options.forEach((option) => {
                  if (
                    (cheapestOption == 0 || option.price < cheapestOption) &&
                    option.price != null
                  ) {
                    cheapestOption = option.price;
                    this.hasOptions = "From";
                  }
                });
              } else {
                this.hasOptions = "";
              }
            });
          } else {
            this.hasOptions = "";
          }

          const productPrice = this.selectedProduct.price + cheapestOption;
          return this.formatCurrency(`${productPrice}`);
        }
      },
      checkTags(tags) {
        this.hasLabel = null;
        if (tags && tags.length) {
          tags.forEach((tag) => {
            // only select the 1st one
            if (tag.tagType.name == "label" && !this.hasLabel) {
              this.hasLabel = tag;
            }
          });
        }
      },
      onOptionSetDialog() {
        this.optionSet.name = this.selectedProduct.name;
        this.dialogSet = true;
      },
      onOptionSetCancel() {
        this.$refs.optionSetForm.reset();
        this.dialogSet = false;
      },
      onCancel(itemId) {
        // v-money clear workaround
        this.$refs.optionForm.$el.getElementsByTagName("input")[1].value = 0;

        this.$refs.optionForm.reset();
        this.dialog = false;
      },
    },

    created: function () {
      this.$store.dispatch("products/getProductById", +this.$props.productId);
      this.getCategories();
      this.getOptions();
    },
  });
</script>

<style>
  .container.container--fluid .container {
    max-width: 100%;
  }

  .upload-btn {
    height: 100% !important;
    text-align: center;
    padding: 15px !important;
  }
  .upload-btn span {
    display: block !important;
  }

  .btn-image {
    width: 60px;
    padding-bottom: 15px;
    margin: 0 auto;
    display: block;
  }

  .preview-img {
    width: 100%;
    max-width: 80%;
    height: auto;
  }

  .btn-text {
    display: block;
  }

  .v-datatable__expand-row {
    background-color: #efefef !important;
  }

  tr.v-data-table__expanded,
  tr.v-data-table__expanded .v-btn.icon {
    color: #fff;
    background: #0a2645 !important;
  }

  tr.v-data-table__expanded .v-btn.parent .v-icon {
    color: #fff;
  }

  .v-badge__badge {
    border-radius: 50%;
    padding: 0;
    width: 13px;
    min-width: 13px;
    height: 13px;
    font-size: 10px;
    line-height: 130%;
    position: absolute;
    left: -35px !important;
    bottom: -15px !important;
    color: #777;
  }

  /* Product preview */
  .headline.text-center {
    justify-content: center;
  }

  .product-preview {
    width: 166px;
    margin: 15px auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .product-preview .header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #c3c5c9;
    font-size: 12px;
    color: #33404d;
    text-align: center;
  }
  .product-preview .header .product-title {
    font-size: 14px;
    font-weight: 600;
    padding: 5px;
    line-height: 1;
  }
  .product-preview .header .sub-title {
    font-size: 12px;
    font-weight: 600;
    padding: 0 0 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .product-preview .image {
    position: relative;
    height: 166px;
    background-color: #fff;
  }

  .product-preview .image img {
    width: 100%;
    padding: 2px;
    height: auto;
    margin: auto;
  }

  .product-preview .image .tags {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
    background-color: rgb(37, 138, 255);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 4px;
  }

  .product-preview .footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    background-color: #697785;
  }
</style>
