import { store } from "@/store";
import Axios from "axios";
import { generateUrl, generateHeader } from "./http-common";

export interface ProductCriteria {
  tags?: { id: number }[];
  organisations?: number[];
  order?: string[];
  minPrice?: number;
  maxPrice?: number;
  available?: boolean;
  startDate?: string;
  endDate?: string;
  productIds?: number[];
  name?: string;
}

export async function getProductsByCriteria(
  body: ProductCriteria,
  page: number = -1,
  pagesize: number = 25,
  showDisabled: boolean,
  connectionTypeCode: string
) {
  body.organisations = [store.state.shops.activeShop.id];
  return Axios.put(generateUrl(`product/criteria`), body, {
    params: {
      page,
      pagesize,
      showDisabled,
      connectionTypeCode,
    },
    headers: await generateHeader(),
  });
}

export async function getProductsToOrder(
  body: ProductCriteria,
  page: number = 1,
  pagesize: number = 25,
  connectionId: number,
  connectionTypeCode: string
) {
  body.organisations = [store.state.shops.activeShop.id];
  return Axios.put(
    generateUrl(`product/criteria/connection/${connectionId}`),
    body,
    {
      params: {
        page,
        pagesize,
        connectionTypeCode,
      },
      headers: await generateHeader(),
    }
  );
}

export async function getProductsGrouped(
  page: number = -1,
  pagesize: number = 25,
  connectionTypeCode: string
) {
  return Axios.get(
    generateUrl(
      `product/organisation/${store.state.shops.activeShop.id}/tag/menu`
    ),
    {
      params: {
        page,
        pagesize,
        connectionTypeCode,
      },
      headers: await generateHeader(),
    }
  );
}

export async function getProducts(
  page: number,
  pagesize: number,
  showDisabled: boolean,
  connectionTypeCode: string,
  sort = "position-asc"
) {
  return Axios.get(
    generateUrl(`product/organisation/${store.state.shops.activeShop.id}`),
    {
      params: {
        page,
        pagesize,
        showDisabled,
        connectionTypeCode,
        sort,
      },
      headers: await generateHeader(),
    }
  );
}

export async function createProduct(body: any) {
  return Axios.post(
    generateUrl(
      `${ProductsUrls.createProductUrl}/${store.state.shops.activeShop.id}`
    ),
    {
      ...body,
    },
    { headers: await generateHeader() }
  );
}

export async function getProductById(productId: number) {
  return Axios.get(generateUrl(`product/${productId}`), {
    headers: await generateHeader(),
  });
}

export async function deleteProduct(id: number) {
  return Axios.delete(generateUrl(`product/${id}`), {
    headers: await generateHeader(),
  });
}

export async function updateProduct(body: any) {
  const putObject = JSON.parse(JSON.stringify(body));

  // The backend api dont want these fields
  delete putObject.id;
  delete putObject.organisation;
  delete putObject.discount;
  delete putObject.groupItems;
  delete putObject.images;
  delete putObject.globalProduct;
  delete putObject.dateCreated;
  delete putObject.lastUpdated;
  delete putObject.optionsPluFlatMappingEnabled;
  delete putObject.stockLevel;

  return Axios.put(
    generateUrl(`${ProductsUrls.updateProductUrl}/${body.id}`),
    {
      ...putObject,
    },
    { headers: await generateHeader() }
  );
}

export async function updateProductStockLevel(
  productId: number,
  quantity: number
) {
  return Axios.put(
    generateUrl(`product/${productId}/stock/${quantity}`),
    {},
    { headers: await generateHeader() }
  );
}

export async function addProductImage(productId: number, imageObject: string) {
  return Axios.put(generateUrl(`product/${productId}/image`), imageObject, {
    headers: {
      ...(await generateHeader()),
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function deleteProductImage(productId: number, imageName: string) {
  return Axios.delete(generateUrl(`product/${productId}/image/${imageName}`), {
    headers: await generateHeader(),
  });
}

namespace ProductsUrls {
  export const getProductsUrl = "product/organisation";
  export const createProductUrl = "product/organisation";
  export const updateProductUrl = "product";
}
